import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CandidateSettingsT,
  TestModusCategoryInfoT,
  TestModusMainCategoryInfoT,
  TestModusQuestionT,
  TestModusSubCategoryTimeT,
  addTmpResult,
} from "./api";
import { ContextPropsT } from "../shared/types/Context";
import { ProfessionT, EMPTYPROFESSION } from "../profession/api";
import { debounce } from "lodash";
import { uglifyNumber, uglifyTimes } from "../shared/helper/uglify";
import { AuthContext } from "../auth/AuthContext";
import { useTimer } from "./useTimer";

/**
 * No: Number
 * Id: Id
 * Ti: Time
 * St: Start
 * Bo: Boolean
 *
 * Que: Question
 * Mai: MainCategory
 * Sub: SubCategory
 * Can: Candidate
 * An: Answer
 * Mem: Memory
 * Cou: Countdown
 */

export enum LSKEYS {
  QUESTION_NO = "NoQue",
  SUB_CAT_ID = "IdSub",
  MAIN_CAT_ID = "IdMai",
  CANDIDATE_ANSWERS = "AnCan",
  TIME_ACT = "TiAct",
  TIME_START = "TiStr",
  TIME_SUBS = "TiSub",
  TIME_MEM = "TiMem",
  TIME_PAUSE = "TiPau",
  IS_PAUSED = "IsPau",
  COUNTDOWN_TIME = "CoTim",
  COUNTDOWN_MEM = "CoMem",
  FINISHED = "Fi",
}

type InitialTimesT = {
  [LSKEYS.TIME_ACT]: number;
  [LSKEYS.TIME_MEM]: number;
  [LSKEYS.TIME_START]: number;
  [LSKEYS.TIME_PAUSE]: number;
};

interface TestModusContextPropsT extends ContextPropsT {
  timeInterval: number;
  setTimeInterval: React.Dispatch<React.SetStateAction<number>>;
  questionNo: number;
  setQuestionNo: React.Dispatch<React.SetStateAction<number>>;
  actMainCategory: number;
  setActMainCategory: React.Dispatch<React.SetStateAction<number>>;
  actSubCategory: number;
  setActSubCategory: React.Dispatch<React.SetStateAction<number>>;
  professionId: number;
  setProfessionId: React.Dispatch<React.SetStateAction<number>>;
  profession: ProfessionT;
  setProfession: React.Dispatch<React.SetStateAction<ProfessionT>>;
  questionList: TestModusQuestionT[];
  setQuestionList: React.Dispatch<React.SetStateAction<TestModusQuestionT[]>>;
  mainCategoryInfo: TestModusMainCategoryInfoT;
  setMainCategoryInfo: React.Dispatch<
    React.SetStateAction<TestModusMainCategoryInfoT>
  >;
  subCategoryInfo: TestModusCategoryInfoT;
  setSubCategoryInfo: React.Dispatch<
    React.SetStateAction<TestModusCategoryInfoT>
  >;
  started: boolean;
  setStarted: React.Dispatch<React.SetStateAction<boolean>>;
  finished: boolean;
  setFinished: React.Dispatch<React.SetStateAction<boolean>>;
  candidateSubCategoryTime: TestModusSubCategoryTimeT;
  setCandidateSubCategoryTime: React.Dispatch<
    React.SetStateAction<TestModusSubCategoryTimeT>
  >;
  setCandidateAnswers: (a: any[]) => void;
  initialTimes: InitialTimesT;
  setInitialTime: (k: LSKEYS, t: number) => void;
  timeTotal: number;
  setTimeTotal: React.Dispatch<React.SetStateAction<number>>;
  clearTimerTotal: () => void;
  startTimerTotal: (i?: number, j?: number) => void;
  stopTimerTotal: () => void;
  restartTimerTotal: () => void;
  timePause: number;
  setTimePause: React.Dispatch<React.SetStateAction<number>>;
  stopTimerPause: () => void;
  startTimerPause: (n?: number) => void;
  restartTimerPause: () => void;
  clearTimerPause: () => void;
  timeoutTotal: boolean;
  candidateSettings: CandidateSettingsT;
  setCandidateSettings: React.Dispatch<
    React.SetStateAction<CandidateSettingsT>
  >;
}

export const testModusContextDefaults: TestModusContextPropsT = {
  timeInterval: 0,
  setTimeInterval: () => {},
  actMainCategory: 0,
  setActMainCategory: () => {},
  actSubCategory: 0,
  setActSubCategory: () => {},
  professionId: 0,
  setProfessionId: () => {},
  profession: EMPTYPROFESSION,
  setProfession: () => {},
  questionNo: 0,
  setQuestionNo: () => {},
  questionList: [],
  setQuestionList: () => {},
  subCategoryInfo: [],
  setSubCategoryInfo: () => {},
  mainCategoryInfo: [],
  setMainCategoryInfo: () => {},
  loadingDone: false,
  setLoadingDone: () => {},
  finished: false,
  setFinished: () => {},
  started: false,
  setStarted: () => {},
  candidateSubCategoryTime: { total: 0 },
  setCandidateSubCategoryTime: () => {},
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  setCandidateAnswers: (a: any[]) => {},
  initialTimes: {
    [LSKEYS.TIME_ACT]: -1,
    [LSKEYS.TIME_MEM]: -1,
    [LSKEYS.TIME_START]: -1,
    [LSKEYS.TIME_PAUSE]: -1,
  },
  setInitialTime: () => {},
  timeTotal: 0,
  setTimeTotal: () => {},
  clearTimerTotal: () => {},
  stopTimerTotal: () => {},
  startTimerTotal: () => {},
  restartTimerTotal: () => {},
  timePause: 0,
  setTimePause: () => {},
  stopTimerPause: () => {},
  startTimerPause: () => {},
  restartTimerPause: () => {},
  clearTimerPause: () => {},
  timeoutTotal: false,
  candidateSettings: {
    compensationPercent: 0,
    compensationPause: 0,
  },
  setCandidateSettings: () => {},
};

export const TestModusContext = createContext<TestModusContextPropsT>(
  testModusContextDefaults
);

export const TestModusProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [questionNo, setQuestionNo] = useState(0);
  const [timeInterval, setTimeInterval] = useState(0);
  const [actMainCategory, setActMainCategory] = useState(0);
  const [actSubCategory, setActSubCategory] = useState(0);
  const [professionId, setProfessionId] = useState(0);
  const [profession, setProfession] = useState(EMPTYPROFESSION);
  const [questionList, setQuestionList] = useState<TestModusQuestionT[]>([]);
  const [loadingDone, setLoadingDone] = useState(false);
  const [mainCategoryInfo, setMainCategoryInfo] = useState({});
  const [subCategoryInfo, setSubCategoryInfo] = useState({});
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [candidateSubCategoryTime, setCandidateSubCategoryTime] = useState({
    total: 0,
  });
  const [timeoutTotal, setTimeoutTotal] = useState(false);
  const [initialTimes, setInitialTimes] = useState<InitialTimesT>({
    [LSKEYS.TIME_ACT]: 0,
    [LSKEYS.TIME_MEM]: 0,
    [LSKEYS.TIME_START]: 0,
    [LSKEYS.TIME_PAUSE]: 0,
  });
  const [candidateSettings, setCandidateSettings] =
    useState<CandidateSettingsT>({
      compensationPercent: 0,
      compensationPause: 0,
    });

  /**
   * O V E R A L L - T I M E
   */
  const {
    time: timeTotal,
    clearTimer: clearTimerTotal,
    stopTimer: stopTimerTotal,
    startTimer: startTimerTotal,
    restartTimer: restartTimerTotal,
    setTime: setTimeTotal,
  } = useTimer(initialTimes[LSKEYS.TIME_START], {
    name: LSKEYS.TIME_START,
  });

  /**
   * P A U S E - T I M E
   */
  const {
    time: timePause,
    setTime: setTimePause,
    stopTimer: stopTimerPause,
    startTimer: startTimerPause,
    restartTimer: restartTimerPause,
    clearTimer: clearTimerPause,
  } = useTimer(initialTimes[LSKEYS.TIME_PAUSE], {
    name: LSKEYS.TIME_PAUSE,
  });
  // console.log("timePause", timePause, initialTimes[LSKEYS.TIME_PAUSE]);

  /* act question no */
  useEffect(() => {
    if (started) {
      localStorage.setItem(LSKEYS.QUESTION_NO, uglifyNumber(questionNo));
      localStorage.setItem(LSKEYS.MAIN_CAT_ID, uglifyNumber(actMainCategory));
      localStorage.setItem(LSKEYS.SUB_CAT_ID, uglifyNumber(actSubCategory));
    }
  }, [questionNo, started, actMainCategory, actSubCategory]);

  /* candidate answers */
  useEffect(() => {
    if (!started) return;

    localStorage.setItem(
      LSKEYS.CANDIDATE_ANSWERS,
      JSON.stringify(questionList.map((q) => q.candidateAnswer))
    );
  }, [questionList, started]);

  /* candidate times */
  useEffect(() => {
    if (!started) return;

    localStorage.setItem(
      LSKEYS.TIME_SUBS,
      JSON.stringify(uglifyTimes(candidateSubCategoryTime))
    );
  }, [candidateSubCategoryTime, started]);

  useEffect(() => {
    console.log(
      "🧛🏿‍♀️ 🧛🏿‍♀️ 🧛🏿‍♀️ 🧛🏿‍♀️ 🧛🏿‍♀️ timeTotal",
      timeTotal,
      "profession.duration",
      profession.duration
    );
    if (profession.duration && timeTotal > profession.duration) {
      setFinished(true);
      clearTimerTotal();
      setTimeoutTotal(true);
    }
  }, [timeTotal]);

  // only every second
  const debouncedSaveTmp = useMemo(
    () =>
      debounce(
        (questionList, json) => {
          addTmpResult(questionList, json, professionId)
            .then(() => {})
            .catch((error) => console.log("error", error));
        },
        1000,
        { leading: true }
      ),
    [professionId]
  );

  const { authState } = useContext(AuthContext);

  /* save continuously all answers given by candidate */
  useEffect(() => {
    if (authState.user.demo) return;
    if (!questionList || !questionList.length) return;

    const jsonData = Object.fromEntries(
      Object.entries(LSKEYS).map(([, value]) => {
        const v = localStorage.getItem(value);
        if (v === null) return [];
        return [value, localStorage.getItem(value)];
      })
    );

    debouncedSaveTmp(questionList, JSON.stringify(jsonData));
  }, [
    authState.user.demo,
    debouncedSaveTmp,
    questionList,
    questionNo,
    actMainCategory,
    actSubCategory,
    candidateSubCategoryTime,
  ]);

  const setCandidateAnswers = (answers: any[]) => {
    setQuestionList((p) =>
      p.map((q, idx) => ({
        ...q,
        candidateAnswer: answers[idx],
      }))
    );
  };

  const setInitialTime = (key: LSKEYS, time: number) => {
    setInitialTimes((p) => ({
      ...p,
      [key]: time,
    }));
  };

  return (
    <TestModusContext.Provider
      value={{
        questionNo,
        setQuestionNo,
        questionList,
        setQuestionList,
        loadingDone,
        setLoadingDone,
        timeInterval,
        setTimeInterval,
        mainCategoryInfo,
        setMainCategoryInfo,
        subCategoryInfo,
        setSubCategoryInfo,
        finished,
        setFinished,
        started,
        setStarted,
        candidateSubCategoryTime,
        setCandidateSubCategoryTime,
        setCandidateAnswers,
        initialTimes,
        setInitialTime,
        actMainCategory,
        setActMainCategory,
        actSubCategory,
        setActSubCategory,
        professionId,
        setProfessionId,
        profession,
        setProfession,
        timeTotal,
        setTimeTotal,
        clearTimerTotal,
        startTimerTotal,
        stopTimerTotal,
        restartTimerTotal,
        timePause,
        setTimePause,
        stopTimerPause,
        startTimerPause,
        restartTimerPause,
        clearTimerPause,
        timeoutTotal,
        candidateSettings,
        setCandidateSettings,
      }}
    >
      {children}
    </TestModusContext.Provider>
  );
};

export const removeTestModusLocalStore = () => {
  removeTestModusLocalStoreLSKEYS();
  localStorage.setItem(LSKEYS.FINISHED, "1");
  localStorage.removeItem("authToken");
};

export const removeTestModusLocalStoreLSKEYS = () => {
  localStorage.removeItem(LSKEYS.CANDIDATE_ANSWERS);
  localStorage.removeItem(LSKEYS.QUESTION_NO);
  localStorage.removeItem(LSKEYS.TIME_ACT);
  localStorage.removeItem(LSKEYS.TIME_SUBS);
  localStorage.removeItem(LSKEYS.MAIN_CAT_ID);
  localStorage.removeItem(LSKEYS.SUB_CAT_ID);
  localStorage.removeItem(LSKEYS.TIME_MEM);
  localStorage.removeItem(LSKEYS.TIME_PAUSE);
};
